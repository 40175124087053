import { defineStore } from 'pinia';

interface GameState {
  isGameViewOpen: boolean;
}

export const useIsGamePlayed = defineStore('isGamePlayed', {
  state: (): GameState => ({
    isGameViewOpen: false,
  }),

  actions: {
    changeGameView(value: boolean) {
      this.isGameViewOpen = value;
    },
  },

  getters: {
    getGameView(state: GameState): boolean {
      return state.isGameViewOpen;
    },
  },
}
);
