<template>
  <div class="preloader">
    <client-only>
      <dot-lottie-vue class="preloader__animation" autoplay loop :data="loaderData" />
      <template #fallback>
        <div class="preloader__static">
          <svg width="296" height="214" viewBox="0 0 296 214" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_11737_384843)">
              <path
                d="M239.184 185.11C239.184 168.788 251.097 156.904 267.627 156.904C284.083 156.904 295.997 168.788 295.997 185.11C295.997 201.431 284.083 213.315 267.627 213.315C251.097 213.315 239.184 201.431 239.184 185.11ZM251.172 185.11C251.172 196.542 258.097 203.838 267.627 203.838C277.084 203.838 284.009 196.542 284.009 185.11C284.009 173.677 277.084 166.381 267.627 166.381C258.097 166.381 251.172 173.677 251.172 185.11Z"
                fill="#1F93FF"
              />
              <path
                d="M184.365 207.448V160.665C184.365 158.409 185.929 156.904 188.237 156.904C190.471 156.904 192.928 158.033 194.417 159.687L218.691 191.578V162.771C218.691 159.236 221 156.904 224.574 156.904C228.073 156.904 230.456 159.236 230.456 162.771V209.554C230.456 211.811 228.892 213.315 226.584 213.315C224.35 213.315 221.893 212.187 220.404 210.532L196.13 178.641V207.448C196.13 210.908 193.822 213.315 190.173 213.315C186.748 213.315 184.365 210.908 184.365 207.448Z"
                fill="#1F93FF"
              />
              <path
                d="M162.361 207.448V162.771C162.361 159.236 164.67 156.904 168.244 156.904C171.743 156.904 174.126 159.236 174.126 162.771V207.448C174.126 210.908 171.818 213.315 168.169 213.315C164.744 213.315 162.361 210.908 162.361 207.448Z"
                fill="#1F93FF"
              />
              <path
                d="M121.444 196.016C122.337 200.829 127.103 204.289 132.762 204.289C138.421 204.289 142.665 200.905 142.665 196.467C142.665 185.034 111.094 194.436 111.094 174.354C111.094 163.674 120.178 156.904 132.911 156.904C145.197 156.904 154.355 163.373 154.355 172.549C154.355 175.783 151.898 177.513 148.994 177.513C146.239 177.513 143.856 176.084 142.739 174.128C141.846 169.39 137.453 165.93 132.166 165.93C126.805 165.93 122.784 169.315 122.784 173.752C122.784 185.11 154.355 175.708 154.355 195.865C154.355 206.621 145.345 213.315 132.762 213.315C120.327 213.315 111.094 206.846 111.094 197.595C111.094 194.361 113.253 192.631 115.934 192.631C118.316 192.631 120.55 194.06 121.444 196.016Z"
                fill="#1F93FF"
              />
              <path
                d="M72.1966 199.551L68.7714 207.298C67.6545 211.284 65.7186 213.315 61.6977 213.315C56.7833 213.315 54.9963 209.93 57.1556 204.665L75.5473 160.966C76.5153 158.484 78.898 156.904 81.6531 156.904C84.3336 156.904 86.7908 158.484 87.7588 160.966L106.15 204.665C108.31 209.93 106.448 213.315 101.534 213.315C97.5131 213.315 95.5771 211.284 94.4602 207.298L91.1095 199.551H72.1966ZM81.6531 172.474L74.356 191.653H88.8757L81.6531 172.474Z"
                fill="#1F93FF"
              />
              <path
                d="M41.3254 174.58C38.7938 169.465 33.8794 166.381 27.9226 166.381C18.6895 166.381 11.9881 173.677 11.9881 185.11C11.9881 196.542 18.6895 203.838 27.9226 203.838C33.8794 203.838 38.7938 200.754 41.3254 195.64C42.5168 193.609 44.6017 192.255 47.5801 192.255C51.6754 192.255 53.6114 195.339 50.8564 199.626C46.3887 208.05 37.8258 213.315 27.3269 213.315C11.4669 213.315 0 201.431 0 185.11C0 168.788 11.4669 156.904 27.3269 156.904C37.8258 156.904 46.3887 162.169 50.8564 170.593C53.6114 174.88 51.6754 177.964 47.5801 177.964C44.6017 177.964 42.5168 176.61 41.3254 174.58Z"
                fill="#1F93FF"
              />
              <path
                d="M0 32.2478C0 14.6805 14.0984 0.439453 31.4896 0.439453H264.512C281.904 0.439453 296.002 14.6805 296.002 32.2478V109.124C296.002 126.692 281.904 140.933 264.512 140.933H31.4896C14.0984 140.933 0 126.692 0 109.124V32.2478Z"
                fill="url(#paint0_linear_11737_384843)"
              />
              <path
                d="M41.703 41.3928C46.8086 41.3928 50.2124 44.9456 50.8931 50.2175L59.7428 87.1208L70.6348 48.2692C71.6559 44.1434 75.1731 41.3928 79.4845 41.3928C83.7959 41.3928 87.1997 44.1434 88.2208 48.2692L99.2262 87.1208L107.962 50.2175C108.757 44.9456 111.934 41.3928 117.153 41.3928C123.279 41.3928 125.435 46.4355 123.279 53.4265L108.303 101.905C107.168 105.802 103.651 108.323 99.5666 108.323C95.4821 108.323 92.1918 105.802 90.9438 101.905L79.4845 64.8872L67.9118 101.905C66.7772 105.802 63.3735 108.323 59.1756 108.323C55.2045 108.323 51.8008 105.802 50.5527 101.905L35.5763 53.4265C33.534 46.4355 35.6897 41.3928 41.703 41.3928Z"
                fill="white"
              />
              <path
                d="M168.559 98.8107V49.6445C168.559 44.7164 171.849 41.3928 176.841 41.3928C181.266 41.3928 184.329 44.1434 184.897 48.2692C189.775 44.1434 196.242 41.3928 203.504 41.3928C219.161 41.3928 230.28 51.3636 230.28 65.0018V98.8107C230.28 103.739 227.103 107.062 221.997 107.062C217.119 107.062 213.828 103.739 213.828 98.8107V67.9815C213.828 60.1883 207.929 54.3434 199.646 54.3434C191.25 54.3434 185.01 60.1883 185.01 67.9815V98.8107C185.01 103.739 181.833 107.062 176.728 107.062C171.849 107.062 168.559 103.739 168.559 98.8107Z"
                fill="white"
              />
              <path
                d="M136.6 99.9523V50.7861C136.6 45.858 139.776 42.5344 144.882 42.5344C149.761 42.5344 153.051 45.858 153.051 50.7861V99.9523C153.051 104.88 149.761 108.204 144.769 108.204C139.89 108.204 136.6 104.88 136.6 99.9523Z"
                fill="white"
              />
              <path
                d="M154.452 25.0408C154.452 30.311 150.223 34.5833 145.005 34.5833C139.788 34.5833 135.559 30.311 135.559 25.0408C135.559 19.7706 139.788 15.4983 145.005 15.4983C150.223 15.4983 154.452 19.7706 154.452 25.0408Z"
                fill="white"
              />
              <path
                d="M248.885 97.9036C248.885 92.5171 252.969 88.5059 259.096 88.5059C264.882 88.5059 268.967 92.5171 268.967 97.9036C268.967 103.29 264.882 107.301 259.096 107.301C252.969 107.301 248.885 103.29 248.885 97.9036Z"
                fill="white"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_11737_384843"
                x1="148.001"
                y1="84.0154"
                x2="148.001"
                y2="140.933"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#23D71F" />
                <stop offset="1" stop-color="#12BF0F" />
              </linearGradient>
              <clipPath id="clip0_11737_384843">
                <rect width="296.002" height="213.121" fill="white" transform="translate(0 0.439453)" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </template>
    </client-only>
  </div>
</template>

<script setup lang="ts">
  import { DotLottieVue } from '@lottiefiles/dotlottie-vue';
  import loaderDataRaw from '~/assets/lottie/loader.json';
  const loaderData = ref(JSON.stringify(loaderDataRaw));
</script>

<style src="~/assets/styles/components/atomic/preloader.scss" lang="scss" />
